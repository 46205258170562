import React from "react"

import { useState, useRef, useEffect, useContext } from "react";
import { navigate } from 'gatsby';
import { css } from "@emotion/core"
import styled from "@emotion/styled";

import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next'
import { I18n } from '../../utils';

// import imageStyles from "./styles.css"

import InstagramImage from "./LoadInstagram"
import GitImage from "./LoadGithub"
import PersonalImage from "./LoadPersonal"
import BlogImage from "./LoadBlog"
import FacebookImage from "./LoadFacebook"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const useStyles = makeStyles(() => ({
  splitScreen: {
    display: 'flex',
    flexDirection: 'column',
  },
  styledImageGithub: {
    left: "50vw",
    top: "50vh",
    transformOrigin: "bottom left",
    transform: "translate(0, -100%)",
    transition: "0.25s linear",
    opacity: "1",
    color: "#fff",
    zIndex: "10",
    "&:hover": {
      transform: "translate(0, -100%) scale(1.10)",
      color: "#dddddd",
      opacity: "1",
    },
    "&:hover&:after": {
      opacity: "0.8",
      color: "#fff",
    },
    "&:hover $overlay": {
      opacity: "1",
    },
    "&:hover $styledImageInner": {
      WebkitFilter: " blur(4px)",
      filter: "blur(4px)",
    },
  },
  styledImageInstagram: {
    left: "50vw",
    top: "50vh",
    transformOrigin: "bottom right",
    transform: "translate(-100%, -100%)",
    transition: "0.25s linear",
    opacity: "0.8",
    color: "#fff",
    zIndex: "10",
    "&:hover": {
      transform: "translate(-100%, -100%) scale(1.10)",
      color: "#dddddd",
      opacity: "1",
    },
    "&:hover $overlay": {
      opacity: "1",
    },
    "&:hover&:after": {
      opacity: "0.8",
      color: "#fff",
    },
    "&:hover $styledImageInner": {
      WebkitFilter: " blur(4px)",
      filter: "blur(4px)",
    },
  },
  styledImageBlog: {
    left: "50vw",
    top: "50vh",
    transformOrigin: "right top",
    transform: "translate(-100%, 0)",
    transition: "0.25s linear",
    opacity: "0.8",
    color: "#fff",
    zIndex: "10",
    "&:hover": {
      color: "#dddddd",
      opacity: "1",
      transform: "translate(-100%, 0) scale(1.10)",
    },
    "&:hover $overlay": {
      opacity: "1",
    },
    "&:hover&:after": {
      opacity: "0.8",
      color: "#fff",
    },
    "&:hover $styledImageInner": {
      WebkitFilter: " blur(4px)",
      filter: "blur(4px)",
    },
  },
  styledImagePersonal: {
    left: "50vw",
    top: "50vh",
    transform: "translate(-50%, -50%)",
    transition: "0.25s linear",
    opacity: "1",
    color: "#fff",
    zIndex: "20",
    "&:hover": {
      transform: "translate(-50%, -50%) scale(1.5)",
      color: "#dddddd",
      opacity: "1",
    },
    "&:hover $overlay": {
      opacity: "1",
    },
    "&:hover&:after": {
      opacity: "0.8",
      color: "#fff",
    },
    "&:hover $styledImageInner": {
      WebkitFilter: " blur(4px)",
      filter: "blur(4px)",
    },
  },
  styledImageFacebook: {
    left: "50vw",
    top: "50vh",
    transformOrigin: "left top",
    transition: "0.25s linear",
    opacity: "0.8",
    color: "#fff",
    zIndex: "10",
    "&:hover": {
      color: "#dddddd",
      opacity: "1",
      transform: "scale(1.10)",
    },
    "&:hover $overlay": {
      opacity: "1",
    },
    "&:hover&:after": {
      opacity: "0.8",
      color: "#fff",
    },
    "&:hover $styledImageInner": {
      WebkitFilter: " blur(4px)",
      filter: "blur(4px)",
    },
  },
  styledImageInner: {
    // "&:hover": {
    //   WebkitFilter: " blur(4px)",
    //   filter: "blur(4px)",
    // },
  },
  overlay: {
    position: "absolute",
    top: "0%",
    left: "0%",
    height: "100%",
    width: "100%",
    opacity: "0",
    transition: ".5s ease",
    zIndex: "15",
    // transform: "translate(-50%, -50%)",
    display: "inline",
  },
  overlayText: {
    backgroundColor: "black",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "900",
    color: "white",
    textTransform: "uppercase",
    margin: "0",
    zIndex: "15",
    position: "absolute",
    top: "50%",
    left: "50%",
    fontSize: "3vmin",
    transform: "translate(-50%, -50%)",
    padding: "1vmin",
  },
  text: {
    color: "white",
    fontSize: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  realityHolderBack: {
    // position: "absolute",
    // zIndex: "8",
    // bottom: "0",
    // right: "0",
    // width: "5vmin",
    // height: "5vmin",
    // background: "red",
  },
  realityHolder: {
    position: "absolute",
    zIndex: "100",
    bottom: "0",
    right: "0",
    width: "5vmin",
    height: "5vmin",
    transition: "all 0.8s",
    overflow: "hidden",

    fontFamily: "'Roboto', sans-serif",
    fontWeight: "900",
    color: "white",

    // width: "0",
    // height: "0",
    // borderTop: "5vmin solid transparent",
    // borderBottom: "5vmin solid transparent",
    // borderLeft: "5vmin solid green",
    // transform: "translate(50%, 50%) rotate(45deg)",
    "& text": {
      fontFamily: "'Roboto', sans-serif",
      fontWeight: "900",
      color: "white",
      backgroundColor: "black",
      opacity: "0",
      position: "fixed",
      top: "+150%",
      left: "+150%",
      transition: "all 5s",
      padding: "1vmin",
    },
    "&:before": {
      content: '""',
      position: "fixed",
      height: "150%",
      width: "150%",
      top: "+150%",
      left: "+150%",
      transition: "all 5s",

      background: "rgb(255,255,255)",
      background: "-moz-linear-gradient(-45deg, rgba(255,255,255,1) 0%, rgba(234,249,255,1) 19%, rgba(0,183,255,1) 45%, rgba(2,178,255,1) 59%, rgba(35,69,255,1) 88%, rgba(8,0,255,0.8379726890756303) 97%, rgba(0,0,0,1) 100%)",
      background: "-webkit-linear-gradient(-45deg, rgba(255,255,255,1) 0%, rgba(234,249,255,1) 19%, rgba(0,183,255,1) 45%, rgba(2,178,255,1) 59%, rgba(35,69,255,1) 88%, rgba(8,0,255,0.8379726890756303) 97%, rgba(0,0,0,1) 100%)",
      background: "linear-gradient(-45deg, rgba(255,255,255,1) 0%, rgba(234,249,255,1) 19%, rgba(0,183,255,1) 45%, rgba(2,178,255,1) 59%, rgba(35,69,255,1) 88%, rgba(8,0,255,0.8379726890756303) 97%, rgba(0,0,0,1) 100%)",
      filter: " progid:DXImageTransform.Microsoft.gradient(startColorstr=\"#ffffff\",endColorstr=\"#000000\",GradientType=1)",

      opacity: "0",
      // transform: " rotate(45deg)",
    },
    "&:hover": {
      "&:before": {
        // top: "+25%",
        // left: " +25%",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        opacity: "1",
        zIndex: "100",
      },
      "& text": {
        opacity: "1",
        top: "0",
        left: "0",
        // width: "100vw",
        // height: "100vh",
        opacity: "1",
        zIndex: "100",
      },
    },
  },
}));

const MainWrapper = styled.div`
width: 100%;
height: 100%;
left: 0;
top: 0;
margin: auto;
position: relative;
display: flex;
flex-direction: row;
overflow: hidden;
`;

const WrapImage = styled.div`
  height: 40vmin;
  width: 40vmin;
  position: absolute;
  `;

const WrapPersonalImage = styled.div`
  height: 40vmin;
  width: 40vmin;
  position: absolute;
  `;

const IconPlace = ({ ref, children, style }): JSX.Element => {

  // const IconPlace: React.FC = ({ children }) => {
  return (
    <div style={{
      position: "fixed",
      justifyContent: 'center', alignItems: 'center',
      width: "100%",
      height: "100%",
    }}>
      <div ref={ref} style={{
        position: "relative", /* or absolute */
        top: "50%",
        width: "50%",
        left: "30%",
        transform: "translate(-50%, -50%)",
        justifyContent: 'center', alignItems: 'center',
      }}>
        <div style={style}>

          <div style={{
            paddingTop: "100%", padding: "10px",
            justifyContent: 'center', alignItems: 'center',
          }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

function doMath(cur_pos, original_pos, total_width) {
  // You have access to this particular instance and its state
  const at_most = total_width * 10 / 100;
  const inputPosX = cur_pos < original_pos ? 0 : original_pos + at_most;

  return inputPosX;
}


const RealityHolder = () => {
  const classes = useStyles();
  const myRef = useRef(null);

  function transitionEndEventName() {
    var el = document.createElement('div')//what the hack is bootstrap

    var transEndEventNames = {
      WebkitTransition: 'webkitTransitionEnd',
      MozTransition: 'transitionend',
      OTransition: 'oTransitionEnd otransitionend',
      transition: 'transitionend'
    }

    for (var name in transEndEventNames) {
      if (el.style[name] !== undefined) {
        return transEndEventNames[name];
      }
    }

    return false // explicit for ie8 (  ._.)
  }

  function endEvent() {
    navigate("/edge");
  }

  useEffect(() => {
    var transitionEnd = transitionEndEventName();

    const setRef = () => {
      // myRef.current.addEventListener(transitionEnd, endEvent, false);
    }

    setRef()

    return () => {
      // myRef.current.removeEventLsitener(transitionEnd, endEvent);
    }
  })

  return (
    <div className={classes.realityHolder} ref={myRef} onClick={() => navigate("/edge")}>
      <text>Staying on the edge huh? Here, something for you</text>
    </div>
  )
}


const LoadMain: React.FC = () => {

  const classes = useStyles();
  const inputLabel = useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);

  const [t] = useTranslation();

  // if (!state || !dispatch) return null;
  const [MousePosition, setMousePosition] = useState({
    left: 0,
    top: 0
  })

  const ref = React.createRef();

  return (
    <MainWrapper >
      <WrapPersonalImage className={classes.styledImagePersonal} onClick={() => navigate("/about")}>
        <PersonalImage className={classes.styledImageInner} />
        <div className={classes.overlay}>
          <div className={classes.overlayText}>
            <div>
              {I18n.t("about")}
            </div>
          </div>
        </div>
      </WrapPersonalImage>
      <WrapImage className={classes.styledImageGithub} onClick={() => openInNewTab("https://gitlab.com/onurkeskin")}>
        <div className={classes.overlay}>
          <div className={classes.overlayText}>
            <div>
              {I18n.t("github")}
            </div>
          </div>
        </div>
        <GitImage className={classes.styledImageInner} />
      </WrapImage >
      <WrapImage className={classes.styledImageInstagram} onClick={() => openInNewTab("https://www.instagram.com/onurcean/")}>
        <div className={classes.overlay}>
          <div className={classes.overlayText}>
            <div>
              {I18n.t("instagram")}
            </div>
          </div>
        </div>
        <InstagramImage className={classes.styledImageInner} />
      </WrapImage>
      <WrapImage className={classes.styledImageBlog} onClick={() => navigate("/blog")}>
        <div className={classes.overlay}>
          <div className={classes.overlayText}>
            <div>
              {I18n.t("blog")}
            </div>
          </div>
        </div>
        <BlogImage className={classes.styledImageInner} />
      </WrapImage>
      <WrapImage className={classes.styledImageFacebook} onClick={() => openInNewTab("https://www.facebook.com/profile.php?id=100002983897453")}>
        < div className={classes.overlay} >
          <div className={classes.overlayText}>
            <div>
              {I18n.t("facebook")}
            </div>
          </div>
        </div>
        <FacebookImage className={classes.styledImageInner} />
      </WrapImage>
      <RealityHolder />
    </MainWrapper >
  );
};


export default LoadMain;