import React, { Component } from "react"
import { makeStyles } from "@material-ui/core/styles";
import { Trans, Translation } from "react-i18next"
import { I18n } from '../../utils';
import { navigate } from 'gatsby';

const useStyles = makeStyles(() => ({
  buttonClass: {
  },
  LanguageSwitcher: {
    display: "block",
    position: "absolute",
    top: "0%",
    right: "0%",
    width: "6%",
    height: "6%",
    minWidth: "50px",

    boxShadow: "var(--shadow-sm)",
    padding: "var(--space-xxs) 0",
    borderTopLeftRadius: "5%",
    borderBottomLeftRadius: "5%",
    borderBottomRightRadius: "5%",
    zIndex: "var(--zindex-popover)",
    // visibility: "hidden",
    // opacity: "0",
    transition: ".2s ease- out",
    border: "0px",
    outline: "0px",

    textDecoration: "none",
    textAlign: "left",
    fontFamily: "Raleway",
    color: "#546e7a",
    fontSize: "2vmin",
    fontWeight: "700",
    lineHeight: "24px",
  },

  customSel: {
    textDecoration: "none",
    textTransform: "uppercase",
    margin: "0",
    padding: "1vmin",
    textAlign: "left",
    fontFamily: "Raleway",
    color: "#546e7a",
    fontSize: "calc(12px + 1vmin)",
    fontWeight: "700",
    // lineHeight: "4px",
    display: "block",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#EDF0F2",
      color: "#ffffff",
    },
    "&:selected": {
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
        color: "#546e7a",
      },
    },
  },

}));

function handleChangeLanguage(lng) {
  I18n.changeLanguage(lng);
  navigate("/");
}


class LanguageComponent extends Component {
  constructor(props) {
    super(props)
  }


  renderLanguageChoice(classes, { code, label }) {
    return (
      <option
        value={code}
        className={classes.customSel}
      >
        {label}
      </option >
    )
  }

  render() {
    const classes = this.props.classes;

    const languages = [
      { code: "en", label: "EN" },
      { code: "tr", label: "TR" },
    ]

    return (
      <select className={classes.LanguageSwitcher} data-width="fit"
        onChange={(e) => handleChangeLanguage(e.target.value)} >
        { languages.map(language => this.renderLanguageChoice(classes, language))}
      </ select>
    )
  }
}


const LanguageSwitcher: React.FC = () => {
  const classes = useStyles();

  return (
    <LanguageComponent classes={classes} />
  );
}

export default LanguageSwitcher