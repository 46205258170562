import React from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import Img, { GatsbyImageProps } from "gatsby-image"
import styled from "@emotion/styled";


const FacebookWrapper = styled.div`
width: 100%;
height: 100%;
left: 0;
top: 0;
margin: auto;
position: absolute;
overflow: hidden;
`;

const FaceBookImage: React.FC<GatsbyImageProps> = ({ children, className, imgStyle }) => {
    const data = useStaticQuery(graphql`
      query {
        placeholderImage: file(relativePath: { eq: "facebook_image2.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            },
          }
        }
      }
    `);

    return (
        <FacebookWrapper className={className}>
            <Img imgStyle={imgStyle} fadeIn={true} durationFadeIn={3000} fluid={data.placeholderImage.childImageSharp.fluid}>
            </Img>
            {children}
        </FacebookWrapper >
    );
}

export default FaceBookImage
