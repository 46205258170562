import React from "react"
import { Helmet } from "react-helmet"
import { Router, Link, Redirect, Location } from "@reach/router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import posed, { PoseGroup } from 'react-pose';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import Layout from "../layouts";
import LoadMain from "../components/LandingPage";
import Copyright from "../components/Copyright";
import Player from "../components/Player";
import Main from "../components/Utils/Main"
import { I18n } from "../utils"
import MovingStyledFullBackground from "../components/Background"
import LanguageSwitcher from "../components/Utils/LanguageSwitcher"
import LazyComponent from "../components/Utils/LazyComponent"

import "../styles/styles.css"

const RouteContainer = posed.div({
  enter: {
    opacity: 1,
    delay: 300,
    beforeChildren: 300,
  },
  exit: { opacity: 0 }
});

const PosedRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <PoseGroup>
        <RouteContainer key={location.pathname} className="wtf">
          <Router className="wtf" location={location}>{children}</Router>
        </RouteContainer>
      </PoseGroup>
    )}
  </Location>
);


const About = React.lazy(() => import('../components/AboutMe'));
const Blog = React.lazy(() => import('../components/Blog'));
const Edgy = React.lazy(() => import('../components/Edgy'));

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Onur Keskin</title>
        <link rel="canonical" href="http://onurkeskin.app" />
      </Helmet>
      <MovingStyledFullBackground>
      </MovingStyledFullBackground >
      <Main>
        <PosedRouter>
          {/* <Route path="/" component={LoadMain} /> */}
          <LoadMain path="/" />
          {/* </Router> </LoadMain> */}
          <LazyComponent Component={About} path="about" />
          <LazyComponent Component={Blog} path="blog/*" />
          <LazyComponent Component={Edgy} path="edge" />
        </PosedRouter>
      </Main>
      <LanguageSwitcher />
      <Player />
      <Copyright />
    </Layout >
  );
};

export default IndexPage;