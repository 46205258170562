import React from "react"
import styled from "@emotion/styled";

const PageMiddle = styled.div`
  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
`;

const LoadingBar: React.FC = ({ children }) => {
    return (
        <PageMiddle>Loading...</PageMiddle>
    );
};

const LazyComponent = ({ Component, ...props }) => (
    <React.Suspense fallback={<LoadingBar />}>
        <Component {...props} />
    </React.Suspense>
);

export default LazyComponent;