import React from 'react';
import { makeStyles } from "@material-ui/core/styles";



const useStyles = makeStyles(() => ({
    CopyrightBoxStyle: {
        position: "fixed",
        bottom: "0",
        width: "100%",
        textAlign: "center",
    },
    CopyrightTextStyle: {
        position: "fixed",
        bottom: "0",
        width: "100%",
        textAlign: "center",
        color: "white",
        textShadow: "0 1px rgba(255, 255, 255, 0.1)",
        fontSize: "1vh",
        background: "black",
    },
})
);


const Copyright = () => {
    const classes = useStyles();

    return (

        <text className={classes.CopyrightTextStyle}>&copy; Copyright 2020 Onur Keskin</text>

    )
}

export default Copyright;