import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import styled from 'styled-components'


const PlayerHolder = styled.div`
  position: fixed;
  top: 0;
  left: 0:
  padding-left: 1vw;
  padding-top: 1vh;
  display: flex;
  overflow: hidden;
`

const useStyles = makeStyles(() => ({
    controlller: {
        display: "flex",
        "&:hover $iFrameStyle": {
            transform: "translate(0,0)",
            transition: "all 1s",
        },
    },
    playerIcon: {
        position: "relative",
        // paddingLeft: "11px",
        height: "60px",
        width: "60px",
        textAlign: "center",
        background: "white",
        zIndex: "1",
        transition: "all 2s",
    },
    // iFrameStyle: {
    //     border: "0",
    //     width: "100%",
    //     height: "42px",

    //     transition: "all 2s",
    //     transform: "translate(-100%,0)",
    // }
    iFrameStyle: {
        width: "100%",
        height: "60px",
        scrolling: "no",
        frameBorder: "no",
        allow: "autoplay",
        transition: "all 2s",
        transform: "translate(-100%,0)",
    },
    randomClassName: {
        fontSize: "10px",
        color: "#cccccc",
        lineBreak: "anywhere",
        wordBreak: "normal",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
        fontWeight: "100",
    }
}
)
);

const Player = () => {
    const classes = useStyles();
    return (
        <PlayerHolder>
            <div className={classes.controlller}>
                <div className={classes.playerIcon}>
                    <h1>
                        ♪
                    </h1>
                </div>
                <iframe className={classes.iFrameStyle}
                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/908715757&color=%23481c60&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true">
                </iframe>
                <div className={classes.randomClassName}>
                    <a href="https://soundcloud.com/paco-fernandez-390319719"
                        title="LOFI BEATS"
                        target="_blank"
                    // style="color: #cccccc; text-decoration: none;"
                    >
                    </a>
                </div>
                {/* <iframe className={classes.iFrameStyle}
                    src="https://bandcamp.com/EmbeddedPlayer/album=4169335970/size=small/bgcol=ffffff/linkcol=0687f5/track=3487514334/transparent=true/"
                    seamless>
                    <a href="https://maxgrigoryev.bandcamp.com/album/act-one">
                        ACT ONE by Max Grigoryev
                    </a>
                </iframe> */}
            </div>
        </PlayerHolder >
    );
}


export default Player;