import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img, { GatsbyImageProps } from "gatsby-image"

const InstagramImage: React.FC<GatsbyImageProps> = ({ className, imgStyle }) => {
  const data = useStaticQuery(graphql`
      query {
        placeholderImage: file(relativePath: { eq: "instagram_image2.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `);

  return (
    <div>
      <Img className={className} imgStyle={imgStyle} fadeIn={true} durationFadeIn={3000} fluid={data.placeholderImage.childImageSharp.fluid} />
    </div >
  );
}

export default InstagramImage