import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import tpersonalInfoTr from '../locales/tr/personalInfo.json';
import tpersonalInfoEn from '../locales/en/personalInfo.json';

const resources = {
    tr: {
        personalInfo: tpersonalInfoTr,
    },
    en: {
        personalInfo: tpersonalInfoEn,
    },
};

i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        ns: ['personalInfo'],
        defaultNS: 'personalInfo',
        react: {
            wait: true,
            useSuspense: false,
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        detection: {
            order: ['path'],
        },
    });

export default i18n;