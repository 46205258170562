import React from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import Img, { GatsbyImageProps } from "gatsby-image"
import styled from "@emotion/styled";


const GitWrapper = styled.div`
width: 100%;
height: 100%;
left: 0;
top: 0;
margin: auto;
position: absolute;
overflow: hidden;
`;

const GitImage: React.FC<GatsbyImageProps> = ({ children, className, imgStyle }) => {
  const data = useStaticQuery(graphql`
      query {
        placeholderImage: file(relativePath: { eq: "github_repo_image.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            },
          }
        }
      }
    `);

  return (
    <GitWrapper className={className}>
      <Img imgStyle={imgStyle} fadeIn={true} durationFadeIn={3000} fluid={data.placeholderImage.childImageSharp.fluid}>
      </Img>
    </GitWrapper >
  );
}

export default GitImage
