import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'


import BackgroundImage from 'gatsby-background-image'
import { StyledFullScreenWrapper } from '../Utils/SharedStyledComponents'

/**
 * In this functional component a fullscreen <BackgroundImage />  is created.
 * @param className   string    className(s) from styled-components.
 * @param children    nodes     Child-components.
 * @return {*}
 * @constructor
 */
const FullBackground = ({ className, children }) => {
  const data = useStaticQuery(graphql`
      query {
        placeholderImage: file(relativePath: { eq: "background2.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `);

  return (
    <StyledFullScreenWrapper>
      <BackgroundImage
        Tag="section"
        className={className}
        fluid={data.placeholderImage.childImageSharp.fluid}
        backgroundColor={`#040e18`}
        id="fullscreenbg"
        role="img"
        preserveStackingContext={true}
      >
        {children}
      </BackgroundImage>
    </StyledFullScreenWrapper >
  )
}

const StyledFullBackground = styled(FullBackground)`
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`

const MovingStyledFullBackground: React.FC = ({ children }) => {
  return (
    <StyledFullBackground>
      {children}
    </StyledFullBackground>
  );
}

export default MovingStyledFullBackground