import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img, { GatsbyImageProps } from "gatsby-image"

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

const PersonalImage: React.FC<GatsbyImageProps> = ({ className, imgStyle }) => {
  const data = useStaticQuery(graphql`
      query {
        placeholderImage: file(relativePath: { eq: "personal_image.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `);

  return (
    <div>
      <Img className={className} imgStyle={imgStyle} fadeIn={true} durationFadeIn={3000} fluid={data.placeholderImage.childImageSharp.fluid} />
    </div >
  );
}

export default PersonalImage